import type { Offcanvas } from 'bootstrap';

export const useBootstrapOffcanvas = (
  element: Ref<HTMLElement>,
  offcanvasOptions?: Offcanvas.Options,
  onShown?: () => void,
  onHidden?: () => void,
) => {
  const instance = ref<Offcanvas>();
  const instanceAvailable = ref(false);

  if (onShown != null) {
    useEventListener(element, 'shown.bs.offcanvas', onShown);
  }

  if (onHidden != null) {
    useEventListener(element, 'hidden.bs.offcanvas', onHidden);
  }

  const showOffcanvas = () => {
    if (instance.value != null) {
      instance.value.show();
    }
  };

  const hideOffcanvas = () => {
    if (instance.value != null) {
      instance.value.hide();
    }
  };

  onMounted(async () => {
    const { default: Offcanvas } = await import('bootstrap/js/dist/offcanvas');
    instance.value = new Offcanvas(element.value, offcanvasOptions);
    instanceAvailable.value = true;
  });

  onUnmounted(() => {
    if (instance.value != null) {
      instance.value.dispose();
    }
  });

  return { instance, instanceAvailable, showOffcanvas, hideOffcanvas };
};

export default useBootstrapOffcanvas;
