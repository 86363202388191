<template>
  <div>
    <div
      class="d-block d-md-none position-relative w-100 py-4 text-decoration-none pe-auto cursor-pointer"
      @click="toggleCollapse()"
    >
      <h2 class="fs-6 mb-0">{{ title }}</h2>
      <fa-icon
        class="position-absolute top-50 end-0 translate-middle-y d-block text-rhino me-4"
        :style="{
          transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
        }"
        :icon="faChevronDown"
      />
    </div>

    <h2 class="fs-6 d-none d-md-block">
      {{ title }}
    </h2>

    <div ref="collapsibleContainer" class="collapse d-md-block pb-md-0">
      <ul class="mb-4 mb-md-0">
        <li
          v-for="link in links"
          :key="
            link.data.url != null
              ? `${link.data.url}__${link.key}`
              : `${link.label}__${link.key}`
          "
          class="lh-md"
        >
          <footer-link-anchor :footer-link="link" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Collapse } from 'bootstrap';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { type FooterLink } from './types';

const props = defineProps({
  title: {
    type: String,
    default: 'Panel Title',
  },
  links: {
    type: Array as PropType<Array<FooterLink>>,
    default: () => [],
  },
});

const collapsibleContainer = ref<HTMLElement>(null);
const collapsibleInstance = ref<Collapse>(null);
const isCollapsed = ref<boolean>(true);

const toggleCollapse = () => {
  collapsibleInstance.value.toggle();
  isCollapsed.value = !isCollapsed.value;
};

onMounted(async () => {
  if (process.client) {
    // manual import of collapse for client
    const { default: CollapseClass } = await import(
      'bootstrap/js/dist/collapse'
    );

    collapsibleInstance.value = new CollapseClass(collapsibleContainer.value, {
      toggle: !isCollapsed.value,
    });
  }
});

onUnmounted(() => {
  collapsibleInstance.value.dispose();
});
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';
@include media-breakpoint-up(md) {
  .collapse {
    display: block !important;
  }
}
</style>
