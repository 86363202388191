<template>
  <div>
    <hr class="footer-line mt-16 mb-2 mt-md-6 mb-mt-28" />
    <div class="d-block d-lg-flex w-100">
      <div class="footer-address mt-8 text-center text-lg-start">
        <router-link
          class="mb-8"
          :to="`/${locale}`"
          :aria-label="t('general/menu/back-to-home')"
        >
          <common-gportal-logo />
        </router-link>

        <p class="mt-4 mb-0 fw-bolder text-rhino lh-md">
          EU: Ociris GmbH
          <span class="d-inline d-lg-none">|</span>
          <br class="d-none d-lg-inline" />
          Aschauer Str. 32a
          <span class="d-inline d-lg-none">|</span>
          <br class="d-none d-lg-inline" />
          81549 München
        </p>

        <p class="mt-4 mb-0 fw-bolder text-rhino lh-md">
          US: GPORTAL, Inc <span class="d-inline d-lg-none">|</span
          ><br class="d-none d-lg-inline" />
          3416 Hillcrest Dr <span class="d-inline d-lg-none">|</span
          ><br class="d-none d-lg-inline" />
          Waco, TX 76708
        </p>
      </div>
      <div class="footer-links mt-8">
        <div class="row">
          <footer-link-group
            class="col-12 col-md-3 border-top border-md-0 text-center text-md-start"
            :title="t('frontend/popular-games')"
            :links="popularGamesLinks"
          />

          <footer-link-group
            class="col-12 col-md-3 border-top border-md-0 text-center text-md-start"
            :title="t('general/menu/block/account')"
            :links="profileLinks"
          />

          <footer-link-group
            class="col-12 col-md-3 border-top border-bottom border-md-0 text-center text-md-start"
            :title="'GPORTAL'"
            :links="gportalLinks"
          />

          <div
            class="col-12 col-md-3 mt-8 mt-md-0 d-md-flex flex-column justify-content-between"
          >
            <footer-locale-switcher />
            <!-- <footer-trust-pilot class="mt-8 mt-md-0 mx-auto mx-md-0" /> -->
          </div>
          <footer-support-info class="col-12 col-md-3 mt-8" />
          <footer-social-media class="col-12 col-md-3 mt-8" />
          <footer-payment-options class="col-12 col-md-6 mt-8" />
        </div>
      </div>
    </div>
    <p class="mt-8 text-rhino">
      {{ t('footer/footer/terms') }}
    </p>
  </div>
</template>

<script lang="ts">
import { useFooterLinks } from './features';

export default defineComponent({
  async setup() {
    const { locale, t } = useI18n();
    const { popularGamesLinks, profileLinks, gportalLinks } =
      await useFooterLinks();

    return {
      locale,
      t,
      profileLinks,
      gportalLinks,
      popularGamesLinks,
    };
  },
});
</script>
<style lang="scss" scoped>
@import 'gportal-theme/scss/colors';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.footer {
  &-address {
    flex: 1;
  }

  &-links {
    flex: 4;
  }
}

@include media-breakpoint-up(md) {
  .border-md-0 {
    border: 0 !important;
  }
}
.border-top,
.border-bottom {
  border-color: $gp-rhino !important;
}
</style>
