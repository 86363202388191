import type {
  AbstractSideNavigationItem,
  SideNavigationGroupItem,
} from '../../types';

export const useLabelTranslation = () => {
  const { t } = useI18n();
  const getTranslation = (
    item: AbstractSideNavigationItem | SideNavigationGroupItem,
  ) => {
    if (item.translate) return t(item.label);

    return item.label;
  };

  return getTranslation;
};

export default { useLabelTranslation };
