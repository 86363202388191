<template>
  <div class="text-center text-md-start">
    <h2 class="fs-6 mb-0">
      {{ t('general/footer/block/payment_options/title') }}
    </h2>
    <div class="d-flex flex-wrap">
      <div
        v-for="paymentOption in paymentOptions"
        :key="paymentOption"
        class="col-3 col-xxl-2 mt-4"
      >
        <img
          :src="`/sc/images/svgs/payment/${paymentOption}.svg`"
          :alt="paymentOption"
          width="50"
          height="30"
          loading="lazy"
          class="footer__payment-options__icon"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    paymentOptions: {
      type: Array as PropType<Array<string>>,
      default: () => [
        'sofort',
        'paysafecard',
        'paypal',
        'visa',
        'amex',
        'mastercard',
      ],
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
});
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';
.footer__payment-options__icon {
  width: auto;
  height: 2.5rem;

  @include media-breakpoint-up(xl) {
    height: 2rem;
  }
}
</style>
