<template>
  <nuxt-link
    v-if="footerLink.type === 'nuxt'"
    :to="footerLink.data.url"
    class="text-capitalize footer__link-anchor text-secondary text-decoration-none"
  >
    {{ translateLabel(footerLink) }}
  </nuxt-link>
  <span
    v-else-if="footerLink.type === 'native'"
    class="text-capitalize footer__link-anchor text-secondary cursor-pointer"
    @click="footerLink.data.onclick"
  >
    {{ translateLabel(footerLink) }}
  </span>
  <a
    v-else
    :href="footerLink.data.url"
    class="text-capitalize footer__link-anchor text-secondary text-decoration-none"
    :target="footerLink.type === 'external' ? '_blank' : null"
    :rel="footerLink.type === 'external' ? 'noopener' : null"
  >
    {{ translateLabel(footerLink) }}
  </a>
</template>

<script lang="ts">
import { useLabelTranslation } from '../navigation/sidebar/features/utils';
import type { FooterLink } from './types';

export default defineComponent({
  props: {
    footerLink: {
      type: Object as PropType<FooterLink>,
      required: true,
    },
  },
  setup() {
    const translateLabel = useLabelTranslation();

    return { translateLabel };
  },
});
</script>

<style scoped lang="scss">
.footer__link-anchor {
  &:hover {
    color: white !important;
  }
}
</style>
