<template>
  <ul data-cy="currency-section">
    <li
      class="border-bottom border-rhino pb-2 fs-6 fw-bold text-rhino text-uppercase"
      data-cy="currency-header"
    >
      {{ t('general/footer/block/locales/currency') }}
    </li>
    <template v-for="{ currency, isSelected } in availableCurrencies">
      <li
        v-if="isSelected"
        :key="currency"
        class="mt-2"
        :data-cy="dataCyName(['currency', currency])"
      >
        <strong>{{ currency }}</strong>
      </li>
      <li
        v-else
        :key="`${currency}selectable`"
        class="mt-2"
        :data-cy="dataCyName(['currency', currency])"
        @click="setCurrency(currency)"
      >
        <span class="cursor-pointer anchor-neutral">{{ currency }}</span>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import dataCyName from '~/helpers/cypress';

const emit = defineEmits<{
  (e: 'currency-selected', currency: string): void;
}>();

const { t } = useI18n();
const { supportedCurrencies } = useAppConfig();

const authStore = useAuthStore();
const currencyStore = useCurrencyStore();
const currentCurrency = computed(() => currencyStore.currency);
const availableCurrencies = computed<
  {
    currency: string;
    isSelected: boolean;
  }[]
>(() => {
  const mapCurrency = (c: string, isSelected: boolean) => {
    return {
      currency: c,
      isSelected,
    };
  };

  if (authStore.isAuthenticated && authStore.activeCustomer) {
    const activeCustomer = authStore.activeCustomer;
    // user is allowed to change the currency, give available currencies for the region
    if (activeCustomer.isAllowedToSwitchCurrency) {
      return SELECTABLE_CUSTOMER_CURRENCIES[authStore.activeRegion].map((c) =>
        mapCurrency(c, c === currentCurrency.value),
      );
    }

    // restrict currency selection only to the customer currency, if the currency cannot be changed
    return [activeCustomer.wallet.currencyCode].map((c) =>
      mapCurrency(c, true),
    );
  }

  // else show all
  return supportedCurrencies.map((c) =>
    mapCurrency(c, c === currentCurrency.value),
  );
});

const { pushNotification } = useNotifications();
const setCurrency = async (currency: string) => {
  if (authStore.isAuthenticated) {
    try {
      await authStore.changeActiveCustomerCurrency(currency, 'switcher');
    } catch (error) {
      pushNotification({
        title: t('general/non_special_texts/error').toString(),
        message: t('general/error/text').toString(),
        type: 'error',
      });
    }
  } else {
    currencyStore.setCurrency(currency, true);
  }

  emit('currency-selected', currency);
};
</script>

<style scoped></style>
