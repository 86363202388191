<template>
  <div class="text-center text-md-start lh-md">
    <h2 class="fs-6 lh-base">{{ t('meta/support') }}</h2>
    <a :href="supportLink" class="text-secondary text-decoration-none"
      >{{ t('general/menu/block/ticket_system') }}
    </a>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    const { t } = useI18n();
    const supportLink = computed<string>(() => `/support`);
    return { t, supportLink };
  },
});
</script>
