import useAuth from '~/composables/useAuth';
import type { FooterLink } from '../types';
import type {
  GetFooterPopularProductsQuery,
  GetFooterPopularProductsQueryVariables,
} from '~/apollo/types/types';
import { GetFooterPopularProductsDocument } from '~/apollo/types/types';

export const useFooterLinks = async () => {
  const authStore = useAuthStore();
  const currencyStore = useCurrencyStore();
  const currentCurrency = computed<string>(() => currencyStore.currency);
  const { locale } = useI18n();
  const { login } = useAuth();
  const { region } = useRegion();
  const { $sentry } = useNuxtApp();

  const { refresh, data: popularGamesLinks } = await useAsyncData<
    Array<FooterLink>
  >('gp-footer-popular-products', async () => {
    try {
      const { data: response } = await useLegacyApollo<
        GetFooterPopularProductsQuery,
        GetFooterPopularProductsQueryVariables
      >(GetFooterPopularProductsDocument, {
        locale: locale.value,
      });

      if (response.products != null && response.products.length > 0) {
        return response.products.map((game) => ({
          key: game.slug,
          type: 'nuxt',
          translate: false,
          label: game.name,
          data: {
            url: `/${locale.value}/${game.slug}`,
          },
        }));
      }
    } catch (error) {
      $sentry.captureException(error);
    }

    return [];
  });

  watch(locale, () => {
    refresh();
  });

  const profileLinks = computed<Array<FooterLink>>(() =>
    authStore.isAuthenticated
      ? /* LOGGED IN */ [
          {
            key: `${region.value}__my-servers`,
            translate: true,
            type: 'link',
            label: 'general/menu/item/my_servers',
            data: {
              url: `/${region.value}/gamecloud`,
            },
          },
          {
            key: `${region.value}__settings`,
            translate: true,
            type: 'nuxt',
            label: 'general/menu/item/your_settings',
            data: {
              url: `/${locale.value}/profile`,
            },
          },
          {
            key: `${region.value}__payments`,
            translate: true,
            type: 'link',
            label: 'general/menu/item/payments',
            data: {
              url: `/${region.value}/profile/payments`,
            },
          },
          {
            key: `${region.value}__recharge-credit`,
            translate: true,
            type: 'link',
            label: 'general/menu/item/recharge_credit',
            data: {
              url: `/${region.value}/profile/payments/transactions`,
            },
          },
        ]
      : /* NOT LOGGED IN */ [
          {
            key: 'login',
            translate: true,
            type: 'native',
            label: 'general/non_special_text/login',
            data: {
              onclick: login,
            },
          },
          {
            key: 'register',
            translate: true,
            type: 'link',
            label: 'frontend/register',
            data: {
              url: `/${locale.value}/register`,
            },
          },
        ],
  );

  const gportalLinks = computed<Array<FooterLink>>(() => [
    {
      key: 'game-studios',
      translate: true,
      type: 'external',
      label: 'general/menu/item/game_studios',
      data: {
        url: `https://www.g-portal.com/business/`,
      },
    },
    {
      key: 'partner',
      translate: true,
      type: 'nuxt',
      label: 'general/menu/item/partner',
      data: {
        url: `/${locale.value}/partner`,
      },
    },
    {
      key: `sustainability`,
      label: 'general/menu/item/sustainability',
      translate: true,
      type: 'nuxt',
      data: {
        url: `/${locale.value}/sustainability`,
      },
    },
    {
      key: 'status',
      translate: true,
      type: 'external',
      label: 'frontend/status',
      data: {
        url: `https://status.g-portal.com`,
      },
    },
    {
      key: 'jobs',
      translate: true,
      type: 'external',
      label: 'frontend/jobs',
      data: {
        url: `https://www.g-portal.com/business/jobs`,
      },
    },
    {
      key: 'wiki',
      translate: true,
      type: 'external',
      label: 'general/menu/item/wiki',
      data: {
        url: `/wiki/${locale.value}`,
      },
    },
    {
      key: 'forum',
      translate: true,
      type: 'external',
      label: 'general/menu/item/forum',
      data: {
        url: `https://forum.g-portal.com`,
      },
    },
    {
      key: 'terms',
      translate: true,
      type: 'external',
      label: 'meta/terms',
      data: {
        url: `/${getLegacyLocalePrefix(
          locale.value,
          currentCurrency.value,
        )}/terms`,
      },
    },
    {
      key: 'privacy',
      translate: true,
      type: 'external',
      label: 'meta/privacy',
      data: {
        url: `/${getLegacyLocalePrefix(
          locale.value,
          currentCurrency.value,
        )}/privacy`,
      },
    },
    {
      key: 'imprint',
      translate: true,
      type: 'external',
      label: 'meta/imprint',
      data: {
        url: `/${getLegacyLocalePrefix(
          locale.value,
          currentCurrency.value,
        )}/imprint`,
      },
    },
  ]);

  return {
    profileLinks,
    gportalLinks,
    popularGamesLinks,
  };
};
