<template>
  <div class="text-center text-md-start">
    <h2 class="fs-6">{{ t('general/footer/block/locales/title') }}</h2>
    <a
      class="anchor-neutral"
      data-bs-target="#footer__locale-switcher"
      data-bs-toggle="offcanvas"
      aria-controls="footer__locale-switcher"
      role="button"
      data-cy="language-switcher"
    >
      <fa-icon class="text-rhino me-2" :icon="faLocationDot" />
      {{ currentDisplayLanguage }} / {{ currentCurrency }}
    </a>
    <div
      id="footer__locale-switcher"
      ref="localeSelectorContainer"
      class="offcanvas offcanvas-end py-8 px-16"
    >
      <div class="position-relative text-center text-lg-start">
        <button
          class="btn locale-switcher__dismiss-btn position-absolute"
          data-bs-dismiss="offcanvas"
          type="button"
          aria-label="Close Locale Switcher"
        >
          <fa-icon class="text-rhino" :icon="faArrowRight" />
        </button>
        <h2 class="fs-6">{{ t('general/footer/block/locales/title') }}</h2>
      </div>

      <div class="row mt-8">
        <footer-locale-locales-list class="col-12 col-md-6" />
        <footer-locale-currencies-list
          class="col-12 col-md-6"
          @currency-selected="close"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import useBootstrapOffcanvas from '~/composables/bootstrap/useOffcanvas';
import type { GPLocaleObject } from '~/locales/types';

const { localeProperties, t } = useI18n();

const currentDisplayLanguage = computed(
  () => (localeProperties.value as GPLocaleObject).displayLanguage,
);
const currencyStore = useCurrencyStore();
const currentCurrency = computed(() => currencyStore.currency);

const localeSelectorContainer = ref<HTMLElement>();

const { hideOffcanvas: close, instance: localeSelectorInstance } =
  useBootstrapOffcanvas(localeSelectorContainer);

const router = useRouter();
router.beforeEach(() => {
  close();
});
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';
@import 'gportal-theme/scss/colors.scss';

.offcanvas {
  width: 100%;
  background: $gradient;
  visibility: visible;

  @include media-breakpoint-up(lg) {
    width: 500px;
  }
}

.locale-switcher {
  &__dismiss-btn {
    margin-top: -0.5rem;
    left: 0;

    @include media-breakpoint-up(lg) {
      left: -2.5rem;
    }
  }
}
</style>
