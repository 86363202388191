<template>
  <ul data-cy="language-section">
    <li
      class="border-bottom border-rhino pb-2 fs-6 fw-bold text-rhino text-uppercase"
      data-cy="language-header"
    >
      {{ t('general/footer/block/locales/language') }}
    </li>
    <!-- render only on the client side, we do not need to show this on runtime -->
    <client-only>
      <li
        v-for="locale in availableLocales"
        :key="locale.code"
        class="mt-2"
        :data-cy="dataCyName(['language', locale.code])"
      >
        <span v-if="locale.isSelected" class="fw-bold">
          {{ locale.code.toUpperCase() }} / {{ locale.displayLanguage }}
        </span>
        <nuxt-link
          v-else
          class="anchor-neutral cursor-pointer"
          :to="locale.localePath"
        >
          {{ locale.code.toUpperCase() }} / {{ locale.displayLanguage }}
        </nuxt-link>
      </li>
    </client-only>
  </ul>
</template>

<script setup lang="ts">
import dataCyName from '~/helpers/cypress';
import type { GPLocaleObject } from '~/locales/types';

const localizedState = useState<
  Array<{
    locale: string;
    path: string;
  }>
>('localized-slugs', () => []);

const { locale: currentLocale, locales, t } = useI18n();

const switchLocalePath = useSwitchLocalePath();
const mapLocale = (locale: GPLocaleObject) => {
  return {
    code: locale.code,
    displayLanguage: locale.displayLanguage,
    isSelected: locale.code === currentLocale.value,
    localePath: switchLocalePath(locale.code),
  };
};

const availableLocales = computed(() => {
  const availableLocalisedSlugs = localizedState.value.map((locale) => {
    const localeObj = locales.value.find((l) => l.code === locale.locale);
    return {
      code: localeObj.code,
      displayLanguage: localeObj.displayLanguage,
      isSelected: localeObj.code === currentLocale.value,
      localePath: locale.path,
    };
  });

  return availableLocalisedSlugs.length > 0
    ? availableLocalisedSlugs
    : (locales.value as GPLocaleObject[]).map(mapLocale);
});
</script>

<style scoped></style>
