<template>
  <div class="text-center text-md-start">
    <h2 class="fs-6 mb-0">
      {{ t('general/footer/block/social_media/title') }}
    </h2>
    <div class="footer__social-media__grid">
      <div
        v-for="socialMedia in socialMediaProfiles"
        :key="socialMedia.name"
        class="mt-4 text-center text-md-start"
      >
        <a :href="socialMedia.link" target="_blank" rel="noopener">
          <img
            class="footer__social-media__icon"
            :src="`/sc/images/svgs/social/${socialMedia.name}.svg`"
            :alt="socialMedia.name"
            width="40"
            height="40"
            loading="lazy"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export interface SocialLink {
  name: string;
  link: string;
}

export default defineComponent({
  setup() {
    const { t, locale } = useI18n();
    const socialMediaProfiles = computed(() => [
      {
        name: 'facebook',
        link:
          locale.value === 'de'
            ? 'https://www.facebook.com/gportalde'
            : 'https://www.facebook.com/gportalcom',
      },
      {
        name: 'twitter',
        link:
          locale.value === 'de'
            ? 'https://twitter.com/gportalde'
            : 'https://twitter.com/gportalint',
      },
      {
        name: 'instagram',
        link: 'https://www.instagram.com/gportal_official/',
      },
      {
        name: 'tiktok',
        link: 'https://www.tiktok.com/@gportal_official',
      },
      {
        name: 'discord',
        link: 'https://discord.gg/gportal',
      },
    ]);
    return { t, socialMediaProfiles };
  },
});
</script>
<style lang="scss" scoped>
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

/**
* We need display: grid here to get the following layout, bootstrap does not have support for CSS grid
*
* x | x | x
*
* should be centered
*
* x | x | x | x
* x | x
*
* should be aligned to the left
*/
.footer__social-media {
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, max-content));
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    &__grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @include media-breakpoint-up(xl) {
    &__grid {
      justify-content: start;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>
